import { lazy } from 'react';

const RoutesConfig = [
  //#region SITE

  //#region -- SINGLE
  {
    exact: true,
    path: '/app/dashboard/site',
    component: lazy(() => import('src/views/dashboard/DemoBoard'))
  },
  {
    exact: true,
    path: '/app/mediamanager',
    component: lazy(() => import('src/pages/MediaManagerInline'))
  },

  //#endregion

  //#region -- SINGLE-PAGES
  {
    exact: true,
    path: [
      '/app/singles/label/:customLabel',
      '/app/singles/:systemLabel',
      '/app/singles'
    ],
    component: lazy(() => import('src/views/pages/singles'))
  },
  {
    exact: true,
    path: '/app/single/edit/:entityId',
    component: lazy(() => import('src/views/pages/singles/EditView'))
  },
  {
    exact: true,
    path: '/app/single/edit-body/:entityId',
    component: lazy(() => import('src/views/pages/singles/EditView/Body'))
  },
  {
    exact: true,
    path: '/app/single/edit-bodyext/:entityId',
    component: lazy(() => import('src/views/pages/singles/EditView/BodyExt'))
  },
  {
    exact: true,
    path: '/app/single/edit-seo/:entityId',
    component: lazy(() => import('src/views/pages/singles/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/single/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/pages/singles/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/single/view/:entityId',
    component: lazy(() => import('src/views/pages/singles/DetailsView'))
  },
  {
    exact: true,
    path: '/app/single/create',
    component: lazy(() => import('src/views/pages/singles/CreateView'))
  },
  //#endregion

  //#region -- TAGS
  {
    exact: true,
    path: [
      '/app/tags/label/:customLabel',
      '/app/tags/:systemLabel',
      '/app/tags'
    ],
    component: lazy(() => import('src/views/tags'))
  },
  {
    exact: true,
    path: '/app/tag/edit/:entityId',
    component: lazy(() => import('src/views/tags/EditView'))
  },
  {
    exact: true,
    path: '/app/tag/edit-seo/:entityId',
    component: lazy(() => import('src/views/tags/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/tag/edit-description/:entityId',
    component: lazy(() => import('src/views/tags/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/tag/view/:entityId',
    component: lazy(() => import('src/views/tags/DetailsView'))
  },
  {
    exact: true,
    path: '/app/tag/create',
    component: lazy(() => import('src/views/tags/CreateView'))
  },
  //#endregion

  //#region -- DATAS
  {
    exact: true,
    path: [
      '/app/datas/label/:customLabel',
      '/app/datas/:systemLabel',
      '/app/datas'
    ],
    component: lazy(() => import('src/views/datas'))
  },
  {
    exact: true,
    path: '/app/data/edit/:entityId',
    component: lazy(() => import('src/views/datas/EditView'))
  },
  // {
  //   exact: true,
  //   path: '/app/data/edit-seo/:entityId',
  //   component: lazy(() => import('src/views/datas/EditView/Seo'))
  // },
  {
    exact: true,
    path: '/app/data/edit-description/:entityId',
    component: lazy(() => import('src/views/datas/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/data/view/:entityId',
    component: lazy(() => import('src/views/datas/DetailsView'))
  },
  {
    exact: true,
    path: '/app/data/create',
    component: lazy(() => import('src/views/datas/CreateView'))
  },
  //#endregion

  //#region -- MENUS

  //#endregion

  //#region -- NOTIFICATION-RUNS

  //#endregion

  //#region -- DEPLOYMENTS

  //#endregion

  //#region -- AREAS

  //#endregion

  //#endregion

  //#region BLOG

  //#region -- SINGLE
  {
    exact: true,
    path: '/app/dashboard/blog',
    component: lazy(() => import('src/views/dashboard/DemoBoard'))
  },

  //#endregion

  //#region -- BLOGS
  {
    exact: true,
    path: [
      '/app/blogs/label/:customLabel',
      '/app/blogs/:systemLabel',
      '/app/blogs'
    ],
    component: lazy(() => import('src/views/pageroots/blogs'))
  },
  {
    exact: true,
    path: '/app/blog/edit/:entityId',
    component: lazy(() => import('src/views/pageroots/blogs/EditView'))
  },
  {
    exact: true,
    path: '/app/blog/edit-seo/:entityId',
    component: lazy(() => import('src/views/pageroots/blogs/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/blog/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/pageroots/blogs/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/blog/view/:entityId',
    component: lazy(() => import('src/views/pageroots/blogs/DetailsView'))
  },
  {
    exact: true,
    path: '/app/blog/create',
    component: lazy(() => import('src/views/pageroots/blogs/CreateView'))
  },
  //#endregion

  //#region -- PAGE-CATS
  {
    exact: true,
    path: '/app/pagecat/edit/:entityId',
    component: lazy(() => import('src/views/pagecats/pagecats/EditView'))
  },
  {
    exact: true,
    path: '/app/pagecat/edit-seo/:entityId',
    component: lazy(() => import('src/views/pagecats/pagecats/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/pagecat/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/pagecats/pagecats/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/pagecat/view/:entityId',
    component: lazy(() => import('src/views/pagecats/pagecats/DetailsView'))
  },
  {
    exact: true,
    path: '/app/pagecat/create',
    component: lazy(() => import('src/views/pagecats/pagecats/CreateView'))
  },
  //#endregion

  //#region -- POSTS
  {
    exact: true,
    path: [
      '/app/posts/label/:customLabel',
      '/app/posts/:systemLabel',
      '/app/posts'
    ],
    component: lazy(() => import('src/views/pages/posts'))
  },
  {
    exact: true,
    path: '/app/post/edit/:entityId',
    component: lazy(() => import('src/views/pages/posts/EditView'))
  },
  {
    exact: true,
    path: '/app/post/edit-body/:entityId',
    component: lazy(() => import('src/views/pages/posts/EditView/Body'))
  },
  {
    exact: true,
    path: '/app/post/edit-bodyext/:entityId',
    component: lazy(() => import('src/views/pages/posts/EditView/BodyExt'))
  },
  {
    exact: true,
    path: '/app/post/edit-seo/:entityId',
    component: lazy(() => import('src/views/pages/posts/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/post/edit-description/:entityId',
    component: lazy(() => import('src/views/pages/posts/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/post/view/:entityId',
    component: lazy(() => import('src/views/pages/posts/DetailsView'))
  },
  {
    exact: true,
    path: '/app/post/create',
    component: lazy(() => import('src/views/pages/posts/CreateView'))
  },
  //#endregion

  //#endregion

  //#region DOC

  //#region -- SINGLE
  {
    exact: true,
    path: '/app/dashboard/doc',
    component: lazy(() => import('src/views/dashboard/DemoBoard'))
  },

  //#endregion

  //#region -- DOCS
  {
    exact: true,
    path: [
      '/app/docs/label/:customLabel',
      '/app/docs/:systemLabel',
      '/app/docs'
    ],
    component: lazy(() => import('src/views/pageroots/docs'))
  },
  {
    exact: true,
    path: '/app/doc/edit/:entityId',
    component: lazy(() => import('src/views/pageroots/docs/EditView'))
  },
  {
    exact: true,
    path: '/app/doc/edit-seo/:entityId',
    component: lazy(() => import('src/views/pageroots/docs/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/doc/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/pageroots/docs/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/doc/view/:entityId',
    component: lazy(() => import('src/views/pageroots/docs/DetailsView'))
  },
  {
    exact: true,
    path: '/app/doc/create',
    component: lazy(() => import('src/views/pageroots/docs/CreateView'))
  },
  //#endregion

  //#region -- CHAPTERS
  {
    exact: true,
    path: '/app/chapter/edit/:entityId',
    component: lazy(() => import('src/views/pagecats/chapters/EditView'))
  },
  {
    exact: true,
    path: '/app/chapter/edit-seo/:entityId',
    component: lazy(() => import('src/views/pagecats/chapters/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/chapter/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/pagecats/chapters/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/chapter/view/:entityId',
    component: lazy(() => import('src/views/pagecats/chapters/DetailsView'))
  },
  {
    exact: true,
    path: '/app/chapter/create',
    component: lazy(() => import('src/views/pagecats/chapters/CreateView'))
  },
  //#endregion

  //#region -- ARTICLES
  {
    exact: true,
    path: [
      '/app/articles/label/:customLabel',
      '/app/articles/:systemLabel',
      '/app/articles'
    ],
    component: lazy(() => import('src/views/pages/articles'))
  },
  {
    exact: true,
    path: '/app/article/edit/:entityId',
    component: lazy(() => import('src/views/pages/articles/EditView'))
  },
  {
    exact: true,
    path: '/app/article/edit-body/:entityId',
    component: lazy(() => import('src/views/pages/articles/EditView/Body'))
  },
  {
    exact: true,
    path: '/app/article/edit-bodyext/:entityId',
    component: lazy(() => import('src/views/pages/articles/EditView/BodyExt'))
  },
  {
    exact: true,
    path: '/app/article/edit-seo/:entityId',
    component: lazy(() => import('src/views/pages/articles/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/article/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/pages/articles/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/article/view/:entityId',
    component: lazy(() => import('src/views/pages/articles/DetailsView'))
  },
  {
    exact: true,
    path: '/app/article/create',
    component: lazy(() => import('src/views/pages/articles/CreateView'))
  },
  //#endregion

  //#endregion

  //#region GUIDELINE

  //#region -- SINGLE
  {
    exact: true,
    path: '/app/dashboard/guideline',
    component: lazy(() => import('src/views/dashboard/DemoBoard'))
  },

  //#endregion

  //#region -- GUIDELINES
  {
    exact: true,
    path: [
      '/app/guidelines/label/:customLabel',
      '/app/guidelines/:systemLabel',
      '/app/guidelines'
    ],
    component: lazy(() => import('src/views/pageroots/guidelines'))
  },
  {
    exact: true,
    path: '/app/guideline/edit/:entityId',
    component: lazy(() => import('src/views/pageroots/guidelines/EditView'))
  },
  {
    exact: true,
    path: '/app/guideline/edit-seo/:entityId',
    component: lazy(() => import('src/views/pageroots/guidelines/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/guideline/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/pageroots/guidelines/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/guideline/view/:entityId',
    component: lazy(() => import('src/views/pageroots/guidelines/DetailsView'))
  },
  {
    exact: true,
    path: '/app/guideline/create',
    component: lazy(() => import('src/views/pageroots/guidelines/CreateView'))
  },
  //#endregion

  //#region -- GUIDES
  {
    exact: true,
    path: '/app/guide/edit/:entityId',
    component: lazy(() => import('src/views/pagecats/guides/EditView'))
  },
  {
    exact: true,
    path: '/app/guide/edit-seo/:entityId',
    component: lazy(() => import('src/views/pagecats/guides/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/guide/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/pagecats/guides/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/guide/view/:entityId',
    component: lazy(() => import('src/views/pagecats/guides/DetailsView'))
  },
  {
    exact: true,
    path: '/app/guide/create',
    component: lazy(() => import('src/views/pagecats/guides/CreateView'))
  },
  //#endregion

  //#region -- ACTIONS
  {
    exact: true,
    path: [
      '/app/actions/label/:customLabel',
      '/app/actions/:systemLabel',
      '/app/actions'
    ],
    component: lazy(() => import('src/views/pages/actions'))
  },
  {
    exact: true,
    path: '/app/action/edit/:entityId',
    component: lazy(() => import('src/views/pages/actions/EditView'))
  },
  {
    exact: true,
    path: '/app/action/edit-body/:entityId',
    component: lazy(() => import('src/views/pages/actions/EditView/Body'))
  },
  {
    exact: true,
    path: '/app/action/edit-bodyext/:entityId',
    component: lazy(() => import('src/views/pages/actions/EditView/BodyExt'))
  },
  {
    exact: true,
    path: '/app/action/edit-seo/:entityId',
    component: lazy(() => import('src/views/pages/actions/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/action/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/pages/actions/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/action/view/:entityId',
    component: lazy(() => import('src/views/pages/actions/DetailsView'))
  },
  {
    exact: true,
    path: '/app/action/create',
    component: lazy(() => import('src/views/pages/actions/CreateView'))
  },
  //#endregion

  //#endregion

  //#region PLAN

  //#region -- SINGLE
  {
    exact: true,
    path: '/app/dashboard/plan',
    component: lazy(() => import('src/views/dashboard/DemoBoard'))
  },

  //#endregion

  //#region -- PLANS
  {
    exact: true,
    path: [
      '/app/plans/label/:customLabel',
      '/app/plans/:systemLabel',
      '/app/plans'
    ],
    component: lazy(() => import('src/views/pageroots/plans'))
  },
  {
    exact: true,
    path: '/app/plan/edit/:entityId',
    component: lazy(() => import('src/views/pageroots/plans/EditView'))
  },
  {
    exact: true,
    path: '/app/plan/edit-seo/:entityId',
    component: lazy(() => import('src/views/pageroots/plans/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/plan/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/pageroots/plans/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/plan/view/:entityId',
    component: lazy(() => import('src/views/pageroots/plans/DetailsView'))
  },
  {
    exact: true,
    path: '/app/plan/create',
    component: lazy(() => import('src/views/pageroots/plans/CreateView'))
  },
  //#endregion

  //#region -- EVENT-CATS
  {
    exact: true,
    path: '/app/eventcat/edit/:entityId',
    component: lazy(() => import('src/views/pagecats/eventcats/EditView'))
  },
  {
    exact: true,
    path: '/app/eventcat/edit-seo/:entityId',
    component: lazy(() => import('src/views/pagecats/eventcats/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/eventcat/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/pagecats/eventcats/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/eventcat/view/:entityId',
    component: lazy(() => import('src/views/pagecats/eventcats/DetailsView'))
  },
  {
    exact: true,
    path: '/app/eventcat/create',
    component: lazy(() => import('src/views/pagecats/eventcats/CreateView'))
  },
  //#endregion

  //#region -- EVENTS
  {
    exact: true,
    path: [
      '/app/events/label/:customLabel',
      '/app/events/:systemLabel',
      '/app/events'
    ],
    component: lazy(() => import('src/views/pages/events'))
  },
  {
    exact: true,
    path: '/app/event/edit/:entityId',
    component: lazy(() => import('src/views/pages/events/EditView'))
  },
  {
    exact: true,
    path: '/app/event/edit-body/:entityId',
    component: lazy(() => import('src/views/pages/events/EditView/Body'))
  },
  {
    exact: true,
    path: '/app/event/edit-bodyext/:entityId',
    component: lazy(() => import('src/views/pages/events/EditView/BodyExt'))
  },
  {
    exact: true,
    path: '/app/event/edit-seo/:entityId',
    component: lazy(() => import('src/views/pages/events/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/event/edit-description/:entityId',
    component: lazy(() => import('src/views/pages/events/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/event/view/:entityId',
    component: lazy(() => import('src/views/pages/events/DetailsView'))
  },
  {
    exact: true,
    path: '/app/event/create',
    component: lazy(() => import('src/views/pages/events/CreateView'))
  },
  //#endregion

  //#endregion

  //#region FORUM

  //#region -- SINGLE
  {
    exact: true,
    path: '/app/dashboard/forum',
    component: lazy(() => import('src/views/dashboard/DemoBoard'))
  },

  //#endregion

  //#region -- FORUMS
  {
    exact: true,
    path: [
      '/app/forums/label/:customLabel',
      '/app/forums/:systemLabel',
      '/app/forums'
    ],
    component: lazy(() => import('src/views/pageroots/forums'))
  },
  {
    exact: true,
    path: '/app/forum/edit/:entityId',
    component: lazy(() => import('src/views/pageroots/forums/EditView'))
  },
  {
    exact: true,
    path: '/app/forum/edit-seo/:entityId',
    component: lazy(() => import('src/views/pageroots/forums/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/forum/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/pageroots/forums/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/forum/view/:entityId',
    component: lazy(() => import('src/views/pageroots/forums/DetailsView'))
  },
  {
    exact: true,
    path: '/app/forum/create',
    component: lazy(() => import('src/views/pageroots/forums/CreateView'))
  },
  //#endregion

  //#region -- THREADS
  {
    exact: true,
    path: '/app/thread/edit/:entityId',
    component: lazy(() => import('src/views/pagecats/threads/EditView'))
  },
  {
    exact: true,
    path: '/app/thread/edit-seo/:entityId',
    component: lazy(() => import('src/views/pagecats/threads/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/thread/edit-description/:entityId',
    component: lazy(() =>
      import('src/views/pagecats/threads/EditView/Description')
    )
  },
  {
    exact: true,
    path: '/app/thread/view/:entityId',
    component: lazy(() => import('src/views/pagecats/threads/DetailsView'))
  },
  {
    exact: true,
    path: '/app/thread/create',
    component: lazy(() => import('src/views/pagecats/threads/CreateView'))
  }
  //#endregion

  //#region -- POSTINGS
  /*
  {
    exact: true,
    path: [
      '/app/postings/label/:customLabel',
      '/app/postings/:systemLabel',
      '/app/postings'
    ],
    component: lazy(() => import('src/views/postings'))
  },
  {
    exact: true,
    path: '/app/posting/edit/:entityId',
    component: lazy(() => import('src/views/postings/EditView'))
  },
  {
    exact: true,
    path: '/app/posting/edit-body/:entityId',
    component: lazy(() => import('src/views/postings/EditView/Body'))
  },
  {
    exact: true,
    path: '/app/posting/edit-bodyext/:entityId',
    component: lazy(() => import('src/views/pages/postings/EditView/BodyExt'))
  },
  {
    exact: true,
    path: '/app/posting/edit-seo/:entityId',
    component: lazy(() => import('src/views/postings/EditView/Seo'))
  },
  {
    exact: true,
    path: '/app/posting/edit-description/:entityId',
    component: lazy(() => import('src/views/postings/EditView/Description'))
  },
  {
    exact: true,
    path: '/app/posting/view/:entityId',
    component: lazy(() => import('src/views/postings/DetailsView'))
  },
  {
    exact: true,
    path: '/app/posting/create',
    component: lazy(() => import('src/views/postings/CreateView'))
  },
  */
  //#endregion

  //#endregion
];

export default RoutesConfig;
