import * as Enum from 'src/config/Enumerations';

const emptyString = '--';

// GENERIC

export const getEnumLabel = (t, type, value) => {
  if (type === 'AboInterval') return getAboIntervalLabel(t, value);
  if (type === 'AccessLevel') return getAccessLevelLabel(t, value);
  if (type === 'AccessType') return getAccessTypeLabel(t, value);
  if (type === 'ApplicationModule') return getApplicationModuleLabel(t, value);
  if (type === 'ApplicationScope') return getApplicationScopeLabel(t, value);
  if (type === 'ApplicationType') return getApplicationTypeLabel(t, value);
  if (type === 'ApproverType') return getApproverTypeLabel(t, value);
  if (type === 'BaseUnitType') return getBaseUnitTypeLabel(t, value);
  if (type === 'BillingInterval') return getBillingIntervalLabel(t, value);
  if (type === 'BusinessType') return getBusinessTypeLabel(t, value);
  if (type === 'ChannelCategory') return getChannelCategoryLabel(t, value);
  if (type === 'ChannelType') return getChannelTypeLabel(t, value);
  if (type === 'ClientType') return getClientTypeLabel(t, value);
  if (type === 'DatasetType') return getDatasetTypeLabel(t, value);
  if (type === 'DelegateType') return getDelegateTypeLabel(t, value);
  if (type === 'DeploymentHoster') return getDeploymentHosterLabel(t, value);
  if (type === 'DeploymentStatus') return getDeploymentStatusLabel(t, value);
  if (type === 'DocumentStatus') return getDocumentStatusLabel(t, value);
  if (type === 'DocLineStatus') return getDocLineStatusLabel(t, value);
  if (type === 'DocLineType') return getDocLineTypeLabel(t, value);
  if (type === 'EditorRole') return getEditorRoleLabel(t, value);
  if (type === 'EmailStatus') return getEmailStatusLabel(t, value);
  if (type === 'EmailTemplate') return getEmailTemplateLabel(t, value);
  if (type === 'EmailType') return getEmailTypeLabel(t, value);
  if (type === 'Environment') return getEnvironmentLabel(t, value);
  if (type === 'Gender') return getGenderLabel(t, value);
  if (type === 'InvoicingDue') return getInvoicingDueLabel(t, value);
  if (type === 'InvoicingInterval') return getInvoicingIntervalLabel(t, value);
  if (type === 'IterationStatus') return getIterationStatusLabel(t, value);
  if (type === 'JobStatus') return getJobStatusLabel(t, value);
  if (type === 'LicenseArea') return getLicenseAreaLabel(t, value);
  if (type === 'LicenseExtension') return getLicenseExtensionLabel(t, value);
  if (type === 'LicenseScope') return getLicenseScopeLabel(t, value);
  if (type === 'LicenseType') return getLicenseTypeLabel(t, value);
  if (type === 'Marketplace') return getMarketplaceLabel(t, value);
  if (type === 'MediaProvider') return getMediaProviderLabel(t, value);
  if (type === 'MediaScope') return getMediaScopeLabel(t, value);
  if (type === 'MediaType') return getMediaTypeLabel(t, value);
  if (type === 'MenuType') return getMenuTypeLabel(t, value);
  if (type === 'MenuItemType') return getMenuItemTypeLabel(t, value);
  if (type === 'MessageStatus') return getMessageStatusLabel(t, value);
  if (type === 'MessageType') return getMessageTypeLabel(t, value);
  if (type === 'NotifyChannel') return getNotifyChannelLabel(t, value);
  if (type === 'NotifyPriority') return getNotifyPriorityLabel(t, value);
  if (type === 'NotifySound') return getNotifySoundLabel(t, value);
  if (type === 'NotifyStatus') return getNotifyStatusLabel(t, value);
  if (type === 'PageType') return getPageTypeLabel(t, value);
  if (type === 'PaymentType') return getPaymentTypeLabel(t, value);
  if (type === 'Platform') return getPlatformLabel(t, value);
  if (type === 'PreferedContact') return getPreferedContactLabel(t, value);
  if (type === 'PricingType') return getPricingTypeLabel(t, value);
  if (type === 'ProductType') return getProductTypeLabel(t, value);
  if (type === 'Priority') return getPriorityLabel(t, value);
  if (type === 'ProjectStatus') return getProjectStatusLabel(t, value);
  if (type === 'ReceiverType') return getReceiverTypeLabel(t, value);
  if (type === 'ServiceInterval') return getServiceIntervalLabel(t, value);
  if (type === 'StepType') return getStepTypeLabel(t, value);
  if (type === 'SubscriptionInterval')
    return getSubscriptionIntervalLabel(t, value);
  if (type === 'SyncStatus') return getSyncStatusLabel(t, value);
  if (type === 'Template') return getTemplateLabel(t, value);
  if (type === 'TriggerAction') return getTriggerActionLabel(t, value);
  if (type === 'TriggerType') return getTriggerTypeLabel(t, value);
  if (type === 'UserType') return getUserTypeLabel(t, value);
  if (type === 'Visibility') return getVisibilityLabel(t, value);

  if (type === 'JobKind') return getJobKindLabel(t, value);
  if (type === 'ProjectKind') return getProjectKindLabel(t, value);
  if (type === 'PurchaseDocKind') return getPurchaseDocKindLabel(t, value);
  if (type === 'ResourceKind') return getResourceKindLabel(t, value);
  if (type === 'SalesDocKind') return getSalesDocKindLabel(t, value);
  if (type === 'TaskKind') return getTaskKindLabel(t, value);
  return value;
};

// SPECIFIC

export const getAboIntervalLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.AboInterval.Month)
    enumLabel = t('enums2121:AboInterval.Month');
  if (value === Enum.AboInterval.Year)
    enumLabel = t('enums2121:AboInterval.Year');
  if (value === Enum.AboInterval.Perpetual)
    enumLabel = t('enums2121:AboInterval.Perpetual');
  return enumLabel;
};
export const getAccessLevelLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.AccessLevel.User)
    enumLabel = t('enums2121:AccessLevel.User');
  if (value === Enum.AccessLevel.Member)
    enumLabel = t('enums2121:AccessLevel.Member');
  if (value === Enum.AccessLevel.Guest)
    enumLabel = t('enums2121:AccessLevel.Guest');
  if (value === Enum.AccessLevel.Partner)
    enumLabel = t('enums2121:AccessLevel.Partner');
  if (value === Enum.AccessLevel.All)
    enumLabel = t('enums2121:AccessLevel.All');
  return enumLabel;
};
export const getAccessTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.AccessType.Full)
    enumLabel = t('enums2121:AccessType.Full');
  if (value === Enum.AccessType.Editor)
    enumLabel = t('enums2121:AccessType.Editor');
  if (value === Enum.AccessType.None)
    enumLabel = t('enums2121:AccessType.None');
  return enumLabel;
};
export const getApplicationModuleLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.ApplicationModule.Core)
    enumLabel = t('enums2121:ApplicationModule.Core');
  if (value === Enum.ApplicationModule.Sales)
    enumLabel = t('enums2121:ApplicationModule.Sales');
  if (value === Enum.ApplicationModule.Content)
    enumLabel = t('enums2121:ApplicationModule.Content');
  if (value === Enum.ApplicationModule.Booking)
    enumLabel = t('enums2121:ApplicationModule.Booking');
  if (value === Enum.ApplicationModule.Campus)
    enumLabel = t('enums2121:ApplicationModule.Campus');
  if (value === Enum.ApplicationModule.Event)
    enumLabel = t('enums2121:ApplicationModule.Event');
  if (value === Enum.ApplicationModule.Project)
    enumLabel = t('enums2121:ApplicationModule.Project');
  if (value === Enum.ApplicationModule.Service)
    enumLabel = t('enums2121:ApplicationModule.Service');
  if (value === Enum.ApplicationModule.Newsletter)
    enumLabel = t('enums2121:ApplicationModule.Newsletter');
  if (value === Enum.ApplicationModule.Billing)
    enumLabel = t('enums2121:ApplicationModule.Billing');
  return enumLabel;
};
export const getApplicationScopeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.ApplicationScope.Graph)
    enumLabel = t('enums2121:ApplicationScope.Graph');
  if (value === Enum.ApplicationScope.Admin)
    enumLabel = t('enums2121:ApplicationScope.Admin');
  if (value === Enum.ApplicationScope.Manage)
    enumLabel = t('enums2121:ApplicationScope.Manage');
  return enumLabel;
};
export const getApplicationTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.ApplicationType.Client)
    enumLabel = t('enums2121:ApplicationType.Client');
  if (value === Enum.ApplicationType.Site)
    enumLabel = t('enums2121:ApplicationType.Site');
  if (value === Enum.ApplicationType.Custom)
    enumLabel = t('enums2121:ApplicationType.Custom');
  return enumLabel;
};
export const getApproverTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.ApproverType.Department)
    enumLabel = t('enums2121:ApproverType.Department');
  if (value === Enum.ApproverType.Project)
    enumLabel = t('enums2121:ApproverType.Project');
  return enumLabel;
};
export const getBaseUnitTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.BaseUnitType.Hour)
    enumLabel = t('enums2121:BaseUnitType.Hour');
  if (value === Enum.BaseUnitType.Task)
    enumLabel = t('enums2121:BaseUnitType.Task');
  return enumLabel;
};
export const getBillingIntervalLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.BillingInterval.Day)
    enumLabel = t('enums2121:BillingInterval.Day');
  if (value === Enum.BillingInterval.Week)
    enumLabel = t('enums2121:BillingInterval.Week');
  if (value === Enum.BillingInterval.Month)
    enumLabel = t('enums2121:BillingInterval.Month');
  if (value === Enum.BillingInterval.TwoMonths)
    enumLabel = t('enums2121:BillingInterval.TwoMonths');
  if (value === Enum.BillingInterval.Quarter)
    enumLabel = t('enums2121:BillingInterval.Quarter');
  if (value === Enum.BillingInterval.HalfYear)
    enumLabel = t('enums2121:BillingInterval.HalfYear');
  if (value === Enum.BillingInterval.Year)
    enumLabel = t('enums2121:BillingInterval.Year');
  return enumLabel;
};
export const getBusinessTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.BusinessType.Sales)
    enumLabel = t('enums2121:BusinessType.Sales');
  if (value === Enum.BusinessType.Purchase)
    enumLabel = t('enums2121:BusinessType.Purchase');
  return enumLabel;
};
export const getChannelCategoryLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.ChannelCategory.Erp)
    enumLabel = t('enums2121:ChannelCategory.Erp');
  if (value === Enum.ChannelCategory.Office)
    enumLabel = t('enums2121:ChannelCategory.Office');
  if (value === Enum.ChannelCategory.Webshop)
    enumLabel = t('enums2121:ChannelCategory.Webshop');
  if (value === Enum.ChannelCategory.Newsletter)
    enumLabel = t('enums2121:ChannelCategory.Newsletter');
  if (value === Enum.ChannelCategory.Other)
    enumLabel = t('enums2121:ChannelCategory.Other');
  return enumLabel;
};
export const getChannelTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.ChannelType.Custom)
    enumLabel = t('enums2121:ChannelType.Custom');
  if (value === Enum.ChannelType.Office365)
    enumLabel = t('enums2121:ChannelType.Office365');
  if (value === Enum.ChannelType.Dyn365BC)
    enumLabel = t('enums2121:ChannelType.Dyn365BC');
  if (value === Enum.ChannelType.FreeFinance)
    enumLabel = t('enums2121:ChannelType.FreeFinance');
  if (value === Enum.ChannelType.Shopify)
    enumLabel = t('enums2121:ChannelType.Shopify');
  if (value === Enum.ChannelType.Mailjet)
    enumLabel = t('enums2121:ChannelType.Mailjet');
  return enumLabel;
};
export const getClientTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.ClientType.Web) enumLabel = t('enums2121:ClientType.Web');
  if (value === Enum.ClientType.Mobile)
    enumLabel = t('enums2121:ClientType.Mobile');
  if (value === Enum.ClientType.Api) enumLabel = t('enums2121:ClientType.Api');
  return enumLabel;
};
export const getDatasetTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.DatasetType.Avatar)
    enumLabel = t('enums2121:DatasetType.Avatar');
  if (value === Enum.DatasetType.Portfolio)
    enumLabel = t('enums2121:DatasetType.Portfolio');
  if (value === Enum.DatasetType.Testimonial)
    enumLabel = t('enums2121:DatasetType.Testimonial');
  return enumLabel;
};
export const getDelegateTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.DelegateType.User)
    enumLabel = t('enums2121:DelegateType.User');
  if (value === Enum.DelegateType.Member)
    enumLabel = t('enums2121:DelegateType.Member');
  if (value === Enum.DelegateType.Guest)
    enumLabel = t('enums2121:DelegateType.Guest');
  if (value === Enum.DelegateType.Partner)
    enumLabel = t('enums2121:DelegateType.Partner');
  return enumLabel;
};
export const getDeploymentHosterLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.DeploymentHoster.None)
    enumLabel = t('enums2121:DeploymentHoster.None');
  if (value === Enum.DeploymentHoster.Netlify)
    enumLabel = t('enums2121:DeploymentHoster.Netlify');
  if (value === Enum.DeploymentHoster.Gatsby)
    enumLabel = t('enums2121:DeploymentHoster.Gatsby');
  return enumLabel;
};
export const getDeploymentStatusLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.DeploymentStatus.None)
    enumLabel = t('enums2121:DeploymentStatus.None');
  if (value === Enum.DeploymentStatus.Success)
    enumLabel = t('enums2121:DeploymentStatus.Success');
  if (value === Enum.DeploymentStatus.Error)
    enumLabel = t('enums2121:DeploymentStatus.Error');
  return enumLabel;
};
export const getDocumentStatusLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.DocumentStatus.Draft)
    enumLabel = t('enums2121:DocumentStatus.Draft');
  if (value === Enum.DocumentStatus.Open)
    enumLabel = t('enums2121:DocumentStatus.Open');
  if (value === Enum.DocumentStatus.Fulfilled)
    enumLabel = t('enums2121:DocumentStatus.Fulfilled');
  if (value === Enum.DocumentStatus.Closed)
    enumLabel = t('enums2121:DocumentStatus.Closed');
  if (value === Enum.DocumentStatus.Canceled)
    enumLabel = t('enums2121:DocumentStatus.Canceled');
  return enumLabel;
};
export const getDocLineStatusLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.DocLineStatus.Draft)
    enumLabel = t('enums2121:DocLineStatus.Draft');
  if (value === Enum.DocLineStatus.Open)
    enumLabel = t('enums2121:DocLineStatus.Open');
  if (value === Enum.DocLineStatus.Fulfilled)
    enumLabel = t('enums2121:DocLineStatus.Fulfilled');
  if (value === Enum.DocLineStatus.Closed)
    enumLabel = t('enums2121:DocLineStatus.Closed');
  if (value === Enum.DocLineStatus.Canceled)
    enumLabel = t('enums2121:DocLineStatus.Canceled');
  return enumLabel;
};
export const getDocLineTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.DocLineType.Product)
    enumLabel = t('enums2121:DocLineType.Product');
  if (value === Enum.DocLineType.SubProduct)
    enumLabel = t('enums2121:DocLineType.SubProduct');
  if (value === Enum.DocLineType.Header)
    enumLabel = t('enums2121:DocLineType.Header');
  if (value === Enum.DocLineType.Text)
    enumLabel = t('enums2121:DocLineType.Text');
  return enumLabel;
};
export const getEditorRoleLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.EditorRole.None)
    enumLabel = t('enums2121:EditorRole.None');
  if (value === Enum.EditorRole.Editor)
    enumLabel = t('enums2121:EditorRole.Editor');
  if (value === Enum.EditorRole.Publisher)
    enumLabel = t('enums2121:EditorRole.Publisher');
  if (value === Enum.EditorRole.Admin)
    enumLabel = t('enums2121:EditorRole.Admin');
  return enumLabel;
};
export const getEmailStatusLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.EmailStatus.Draft)
    enumLabel = t('enums2121:EmailStatus.Draft');
  if (value === Enum.EmailStatus.Sent)
    enumLabel = t('enums2121:EmailStatus.Sent');
  if (value === Enum.EmailStatus.Spam)
    enumLabel = t('enums2121:EmailStatus.Spam');
  if (value === Enum.EmailStatus.DeletedUnopende)
    enumLabel = t('enums2121:EmailStatus.DeletedUnopende');
  if (value === Enum.EmailStatus.Opened)
    enumLabel = t('enums2121:EmailStatus.Opened');
  if (value === Enum.EmailStatus.Link)
    enumLabel = t('enums2121:EmailStatus.Link');
  if (value === Enum.EmailStatus.Archived)
    enumLabel = t('enums2121:EmailStatus.Archived');
  if (value === Enum.EmailStatus.Deleted)
    enumLabel = t('enums2121:EmailStatus.Deleted');
  return enumLabel;
};
export const getEmailTemplateLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.EmailTemplate.Basic)
    enumLabel = t('enums2121:EmailTemplate.Basic');
  if (value === Enum.EmailTemplate.Confirmation)
    enumLabel = t('enums2121:EmailTemplate.Confirmation');
  if (value === Enum.EmailTemplate.Notification)
    enumLabel = t('enums2121:EmailTemplate.Notification');
  return enumLabel;
};
export const getEmailTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.EmailType.Transaction)
    enumLabel = t('enums2121:EmailType.Transaction');
  if (value === Enum.EmailType.Newsletter)
    enumLabel = t('enums2121:EmailType.Newsletter');
  return enumLabel;
};
export const getEnvironmentLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.Environment.None)
    enumLabel = t('enums2121:Environment.None');
  if (value === Enum.Environment.Web)
    enumLabel = t('enums2121:Environment.Web');
  if (value === Enum.Environment.Mobile)
    enumLabel = t('enums2121:Environment.Mobile');
  return enumLabel;
};
export const getGenderLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.Gender.Undefined)
    enumLabel = t('enums2121:Gender.Undefined');
  if (value === Enum.Gender.Male) enumLabel = t('enums2121:Gender.Male');
  if (value === Enum.Gender.Female) enumLabel = t('enums2121:Gender.Female');
  if (value === Enum.Gender.Diverse) enumLabel = t('enums2121:Gender.Diverse');
  return enumLabel;
};
export const getInvoicingDueLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.InvoicingDue.Before)
    enumLabel = t('enums2121:InvoicingDue.Before');
  if (value === Enum.InvoicingDue.First)
    enumLabel = t('enums2121:InvoicingDue.First');
  if (value === Enum.InvoicingDue.Last)
    enumLabel = t('enums2121:InvoicingDue.Last');
  if (value === Enum.InvoicingDue.After)
    enumLabel = t('enums2121:InvoicingDue.After');
  return enumLabel;
};
export const getInvoicingIntervalLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.InvoicingInterval.Month)
    enumLabel = t('enums2121:InvoicingInterval.Month');
  if (value === Enum.InvoicingInterval.Quarter)
    enumLabel = t('enums2121:InvoicingInterval.Quarter');
  if (value === Enum.InvoicingInterval.HalfYear)
    enumLabel = t('enums2121:InvoicingInterval.HalfYear');
  if (value === Enum.InvoicingInterval.Year)
    enumLabel = t('enums2121:InvoicingInterval.Year');
  if (value === Enum.InvoicingInterval.Period)
    enumLabel = t('enums2121:InvoicingInterval.Period');
  return enumLabel;
};
export const getIterationStatusLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.IterationStatus.Draft)
    enumLabel = t('enums2121:IterationStatus.Draft');
  if (value === Enum.IterationStatus.Active)
    enumLabel = t('enums2121:IterationStatus.Active');
  if (value === Enum.IterationStatus.Closing)
    enumLabel = t('enums2121:IterationStatus.Closing');
  if (value === Enum.IterationStatus.Closed)
    enumLabel = t('enums2121:IterationStatus.Closed');
  return enumLabel;
};
export const getJobStatusLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.JobStatus.Draft)
    enumLabel = t('enums2121:JobStatus.Draft');
  if (value === Enum.JobStatus.Active)
    enumLabel = t('enums2121:JobStatus.Active');
  if (value === Enum.JobStatus.Closing)
    enumLabel = t('enums2121:JobStatus.Closing');
  if (value === Enum.JobStatus.Closed)
    enumLabel = t('enums2121:JobStatus.Closed');
  return enumLabel;
};
export const getLicenseAreaLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.LicenseArea.Organisation)
    enumLabel = t('enums2121:LicenseArea.Organisation');
  if (value === Enum.LicenseArea.Application)
    enumLabel = t('enums2121:LicenseArea.Application');
  if (value === Enum.LicenseArea.MobilePortal)
    enumLabel = t('enums2121:LicenseArea.MobilePortal');
  if (value === Enum.LicenseArea.WebPortal)
    enumLabel = t('enums2121:LicenseArea.WebPortal');
  if (value === Enum.LicenseArea.Connector)
    enumLabel = t('enums2121:LicenseArea.Connector');
  if (value === Enum.LicenseArea.Special)
    enumLabel = t('enums2121:LicenseArea.Special');
  return enumLabel;
};
export const getLicenseExtensionLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.LicenseExtension.Guide)
    enumLabel = t('enums2121:LicenseExtension.Guide');
  if (value === Enum.LicenseExtension.KnowledgeBase)
    enumLabel = t('enums2121:LicenseExtension.KnowledgeBase');
  if (value === Enum.LicenseExtension.Community)
    enumLabel = t('enums2121:LicenseExtension.Community');
  if (value === Enum.LicenseExtension.Booking)
    enumLabel = t('enums2121:LicenseExtension.Booking');
  if (value === Enum.LicenseExtension.Service)
    enumLabel = t('enums2121:LicenseExtension.Service');
  return enumLabel;
};
export const getLicenseScopeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.LicenseScope.Organisation)
    enumLabel = t('enums2121:LicenseScope.Organisation');
  if (value === Enum.LicenseScope.Site)
    enumLabel = t('enums2121:LicenseScope.Site');
  if (value === Enum.LicenseScope.Application)
    enumLabel = t('enums2121:LicenseScope.Application');
  if (value === Enum.LicenseScope.User)
    enumLabel = t('enums2121:LicenseScope.User');
  if (value === Enum.LicenseScope.Member)
    enumLabel = t('enums2121:LicenseScope.Member');
  if (value === Enum.LicenseScope.Guest)
    enumLabel = t('enums2121:LicenseScope.Guest');
  if (value === Enum.LicenseScope.Partner)
    enumLabel = t('enums2121:LicenseScope.Partner');
  return enumLabel;
};
export const getLicenseTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.LicenseType.Plan)
    enumLabel = t('enums2121:LicenseType.Plan');
  if (value === Enum.LicenseType.Service)
    enumLabel = t('enums2121:LicenseType.Service');
  if (value === Enum.LicenseType.Extension)
    enumLabel = t('enums2121:LicenseType.Extension');
  return enumLabel;
};
export const getMarketplaceLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.Marketplace.None)
    enumLabel = t('enums2121:Marketplace.None');
  if (value === Enum.Marketplace.AppStore)
    enumLabel = t('enums2121:Marketplace.AppStore');
  if (value === Enum.Marketplace.PlayStore)
    enumLabel = t('enums2121:Marketplace.PlayStore');
  return enumLabel;
};
export const getMediaProviderLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.MediaProvider.None)
    enumLabel = t('enums2121:MediaProvider.None');
  if (value === Enum.MediaProvider.Cloudinary)
    enumLabel = t('enums2121:MediaProvider.Cloudinary');
  return enumLabel;
};
export const getMediaScopeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.MediaScope.upload)
    enumLabel = t('enums2121:MediaScope.Upload');
  return enumLabel;
};
export const getMediaTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.MediaType.auto) enumLabel = t('enums2121:MediaType.Auto');
  if (value === Enum.MediaType.image)
    enumLabel = t('enums2121:MediaType.Image');
  if (value === Enum.MediaType.video)
    enumLabel = t('enums2121:MediaType.Video');
  if (value === Enum.MediaType.raw) enumLabel = t('enums2121:MediaType.Raw');
  return enumLabel;
};

export const getMenuTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.MenuType.Header)
    enumLabel = t('enums2121:MenuType.Header');
  if (value === Enum.MenuType.Footer)
    enumLabel = t('enums2121:MenuType.Footer');
  if (value === Enum.MenuType.Main) enumLabel = t('enums2121:MenuType.Main');
  if (value === Enum.MenuType.Custom)
    enumLabel = t('enums2121:MenuType.Custom');
  return enumLabel;
};
export const getMenuItemTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.MenuItemType.None)
    enumLabel = t('enums2121:MenuItemType.None');
  if (value === Enum.MenuItemType.Extern)
    enumLabel = t('enums2121:MenuItemType.Extern');
  if (value === Enum.MenuItemType.Home)
    enumLabel = t('enums2121:MenuItemType.Home');
  if (value === Enum.MenuItemType.AreaHome)
    enumLabel = t('enums2121:MenuItemType.AreaHome');
  if (value === Enum.MenuItemType.Page)
    enumLabel = t('enums2121:MenuItemType.Page');
  if (value === Enum.MenuItemType.Author)
    enumLabel = t('enums2121:MenuItemType.Author');
  if (value === Enum.MenuItemType.Blog)
    enumLabel = t('enums2121:MenuItemType.Blog');
  if (value === Enum.MenuItemType.Doc)
    enumLabel = t('enums2121:MenuItemType.Doc');
  if (value === Enum.MenuItemType.Event)
    enumLabel = t('enums2121:MenuItemType.Event');
  if (value === Enum.MenuItemType.Forum)
    enumLabel = t('enums2121:MenuItemType.Forum');
  if (value === Enum.MenuItemType.Guideline)
    enumLabel = t('enums2121:MenuItemType.Guideline');
  if (value === Enum.MenuItemType.Tag)
    enumLabel = t('enums2121:MenuItemType.Tag');
  return enumLabel;
};
export const getMessageStatusLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.MessageStatus.Draft)
    enumLabel = t('enums2121:MessageStatus.Draft');
  if (value === Enum.MessageStatus.Sent)
    enumLabel = t('enums2121:MessageStatus.Sent');
  if (value === Enum.MessageStatus.Read)
    enumLabel = t('enums2121:MessageStatus.Read');
  if (value === Enum.MessageStatus.Closed)
    enumLabel = t('enums2121:MessageStatus.Closed');
  return enumLabel;
};
export const getMessageTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.MessageType.Message)
    enumLabel = t('enums2121:MessageType.Message');
  if (value === Enum.MessageType.Invitation)
    enumLabel = t('enums2121:MessageType.Invitation');
  if (value === Enum.MessageType.Survey)
    enumLabel = t('enums2121:MessageType.Survey');
  return enumLabel;
};
export const getNotifyChannelLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.NotifyChannel.Notification)
    enumLabel = t('enums2121:NotifyChannel.Notification');
  if (value === Enum.NotifyChannel.Mail)
    enumLabel = t('enums2121:NotifyChannel.Mail');
  return enumLabel;
};
export const getNotifyPriorityLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.NotifyPriority.Default)
    enumLabel = t('enums2121:NotifyPriority.Default');
  if (value === Enum.NotifyPriority.Normal)
    enumLabel = t('enums2121:NotifyPriority.Normal');
  if (value === Enum.NotifyPriority.High)
    enumLabel = t('enums2121:NotifyPriority.High');
  return enumLabel;
};
export const getNotifySoundLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.NotifySound.Default)
    enumLabel = t('enums2121:NotifySound.Default');
  return enumLabel;
};
export const getNotifyStatusLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.NotifyStatus.Draft)
    enumLabel = t('enums2121:NotifyStatus.Draft');
  if (value === Enum.NotifyStatus.Active)
    enumLabel = t('enums2121:NotifyStatus.Active');
  if (value === Enum.NotifyStatus.Running)
    enumLabel = t('enums2121:NotifyStatus.Running');
  if (value === Enum.NotifyStatus.Finished)
    enumLabel = t('enums2121:NotifyStatus.Finished');
  if (value === Enum.NotifyStatus.Closed)
    enumLabel = t('enums2121:NotifyStatus.Closed');
  return enumLabel;
};
export const getPageTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.PageType.Single)
    enumLabel = t('enums2121:PageType.Single');
  if (value === Enum.PageType.Post) enumLabel = t('enums2121:PageType.Post');
  if (value === Enum.PageType.Event) enumLabel = t('enums2121:PageType.Event');
  if (value === Enum.PageType.Article)
    enumLabel = t('enums2121:PageType.Article');
  if (value === Enum.PageType.Posting)
    enumLabel = t('enums2121:PageType.Posting');
  if (value === Enum.PageType.Action)
    enumLabel = t('enums2121:PageType.Action');
  return enumLabel;
};
export const getPaymentTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.PaymentType.Bank)
    enumLabel = t('enums2121:PaymentType.Bank');
  if (value === Enum.PaymentType.Bar)
    enumLabel = t('enums2121:PaymentType.Bar');
  return enumLabel;
};
export const getPlatformLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.Platform.iOS) enumLabel = t('enums2121:Platform.iOS');
  if (value === Enum.Platform.Android)
    enumLabel = t('enums2121:Platform.Android');
  return enumLabel;
};
export const getPreferedContactLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.PreferedContact.Phone)
    enumLabel = t('enums2121:PreferedContact.Phone');
  if (value === Enum.PreferedContact.Mobile)
    enumLabel = t('enums2121:PreferedContact.Mobile');
  if (value === Enum.PreferedContact.Sms)
    enumLabel = t('enums2121:PreferedContact.Sms');
  if (value === Enum.PreferedContact.Message)
    enumLabel = t('enums2121:PreferedContact.Message');
  if (value === Enum.PreferedContact.Email)
    enumLabel = t('enums2121:PreferedContact.Email');
  if (value === Enum.PreferedContact.Fax)
    enumLabel = t('enums2121:PreferedContact.Fax');
  return enumLabel;
};
export const getPricingTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.PricingType.Sales)
    enumLabel = t('enums2121:PricingType.Sales');
  if (value === Enum.PricingType.Purchase)
    enumLabel = t('enums2121:PricingType.Purchase');
  return enumLabel;
};
export const getPriorityLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.Priority.Standard)
    enumLabel = t('enums2121:Priority.Standard');
  if (value === Enum.Priority.High) enumLabel = t('enums2121:Priority.High');
  if (value === Enum.Priority.Low) enumLabel = t('enums2121:Priority.Low');
  return enumLabel;
};
export const getProductTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.ProductType.Inventory)
    enumLabel = t('enums2121:ProductType.Inventory');
  if (value === Enum.ProductType.NonInventory)
    enumLabel = t('enums2121:ProductType.NonInventory');
  if (value === Enum.ProductType.Service)
    enumLabel = t('enums2121:ProductType.Service');
  if (value === Enum.ProductType.Subscription)
    enumLabel = t('enums2121:ProductType.Subscription');
  return enumLabel;
};
export const getProjectStatusLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.ProjectStatus.Draft)
    enumLabel = t('enums2121:ProjectStatus.Draft');
  if (value === Enum.ProjectStatus.Active)
    enumLabel = t('enums2121:ProjectStatus.Active');
  if (value === Enum.ProjectStatus.Closing)
    enumLabel = t('enums2121:ProjectStatus.Closing');
  if (value === Enum.ProjectStatus.Closed)
    enumLabel = t('enums2121:ProjectStatus.Closed');
  return enumLabel;
};
export const getReceiverTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.ReceiverType.To)
    enumLabel = t('enums2121:ReceiverType.To');
  if (value === Enum.ReceiverType.Cc)
    enumLabel = t('enums2121:ReceiverType.Cc');
  if (value === Enum.ReceiverType.Bcc)
    enumLabel = t('enums2121:ReceiverType.Bcc');
  return enumLabel;
};
export const getServiceIntervalLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.ServiceInterval.Month)
    enumLabel = t('enums2121:ServiceInterval.Month');
  if (value === Enum.ServiceInterval.Year)
    enumLabel = t('enums2121:ServiceInterval.Year');
  return enumLabel;
};
export const getStepTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.StepType.None) enumLabel = t('enums2121:StepType.None');
  if (value === Enum.StepType.Coordination)
    enumLabel = t('enums2121:StepType.Coordination');
  if (value === Enum.StepType.Meeting)
    enumLabel = t('enums2121:StepType.Meeting');
  if (value === Enum.StepType.Message)
    enumLabel = t('enums2121:StepType.Message');
  if (value === Enum.StepType.Talk) enumLabel = t('enums2121:StepType.Talk');
  if (value === Enum.StepType.Task) enumLabel = t('enums2121:StepType.Task');
  if (value === Enum.StepType.Team) enumLabel = t('enums2121:StepType.Team');
  if (value === Enum.StepType.Extern)
    enumLabel = t('enums2121:StepType.Extern');
  return enumLabel;
};
export const getSubscriptionIntervalLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.SubscriptionInterval.Month)
    enumLabel = t('enums2121:SubscriptionInterval.Month');
  if (value === Enum.SubscriptionInterval.Year)
    enumLabel = t('enums2121:SubscriptionInterval.Year');
  if (value === Enum.SubscriptionInterval.Perpetual)
    enumLabel = t('enums2121:SubscriptionInterval.Perpetual');
  return enumLabel;
};
export const getSyncStatusLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.SyncStatus.Ok) enumLabel = t('enums2121:SyncStatus.Ok');
  if (value === Enum.SyncStatus.Error)
    enumLabel = t('enums2121:SyncStatus.Error');
  if (value === Enum.SyncStatus.Manual)
    enumLabel = t('enums2121:SyncStatus.Manual');
  if (value === Enum.SyncStatus.NotSet)
    enumLabel = t('enums2121:SyncStatus.NotSet');
  return enumLabel;
};
export const getTemplateLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.Template.Standard)
    enumLabel = t('enums2121:Template.Standard');
  if (value === Enum.Template.Contact)
    enumLabel = t('enums2121:Template.Contact');
  if (value === Enum.Template.Data) enumLabel = t('enums2121:Template.Data');
  if (value === Enum.Template.Newsletter)
    enumLabel = t('enums2121:Template.Newsletter');
  if (value === Enum.Template.Message)
    enumLabel = t('enums2121:Template.Message');
  if (value === Enum.Template.Booking)
    enumLabel = t('enums2121:Template.Booking');
  if (value === Enum.Template.Issue) enumLabel = t('enums2121:Template.Issue');
  return enumLabel;
};
export const getTriggerActionLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.TriggerAction.Create)
    enumLabel = t('enums2121:TriggerAction.Create');
  if (value === Enum.TriggerAction.Update)
    enumLabel = t('enums2121:TriggerAction.Update');
  if (value === Enum.TriggerAction.Delete)
    enumLabel = t('enums2121:TriggerAction.Delete');
  if (value === Enum.TriggerAction.Publish)
    enumLabel = t('enums2121:TriggerAction.Publish');
  if (value === Enum.TriggerAction.Unpublish)
    enumLabel = t('enums2121:TriggerAction.Unpublish');
  return enumLabel;
};
export const getTriggerTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.TriggerType.Record)
    enumLabel = t('enums2121:TriggerType.Record');
  if (value === Enum.TriggerType.File)
    enumLabel = t('enums2121:TriggerType.File');
  if (value === Enum.TriggerType.Deployment)
    enumLabel = t('enums2121:TriggerType.Deployment');
  return enumLabel;
};
export const getUserTypeLabel = (t, value) => {
  let enumLabel = emptyString;
  // TODO: add UserType captions
  // if (value === Enum.UserType.None)
  //   enumLabel = t('enums2121:UserType.None');
  // if (value === Enum.UserType.BackendAdmin)
  //   enumLabel = t('enums2121:UserType.BackendAdmin');
  if (value === Enum.UserType.BackendUser)
    enumLabel = t('enums2121:DelegateType.User');
  if (value === Enum.UserType.BackendMember)
    enumLabel = t('enums2121:DelegateType.Member');
  if (value === Enum.UserType.FrontendMember)
    enumLabel = t('enums2121:DelegateType.Member');
  if (value === Enum.UserType.FrontendGuest)
    enumLabel = t('enums2121:DelegateType.Guest');
  if (value === Enum.UserType.FrontendPartner)
    enumLabel = t('enums2121:DelegateType.Partner');
  return enumLabel;
};
export const getVisibilityLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.Visibility.Public)
    enumLabel = t('enums2121:Visibility.Public');
  if (value === Enum.Visibility.Community)
    enumLabel = t('enums2121:Visibility.Community');
  if (value === Enum.Visibility.Team)
    enumLabel = t('enums2121:Visibility.Team');
  if (value === Enum.Visibility.Office)
    enumLabel = t('enums2121:Visibility.Office');
  if (value === Enum.Visibility.Message)
    enumLabel = t('enums2121:Visibility.Message');
  return enumLabel;
};

// Object kinds

export const getJobKindLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.JobKind.Bug) enumLabel = t('enums2121:JobKind.Bug');
  if (value === Enum.JobKind.Case) enumLabel = t('enums2121:JobKind.Case');
  if (value === Enum.JobKind.Complaint)
    enumLabel = t('enums2121:JobKind.Complaint');
  if (value === Enum.JobKind.Course) enumLabel = t('enums2121:JobKind.Course');
  if (value === Enum.JobKind.Feature)
    enumLabel = t('enums2121:JobKind.Feature');
  if (value === Enum.JobKind.Idea) enumLabel = t('enums2121:JobKind.Idea');
  if (value === Enum.JobKind.Requirement)
    enumLabel = t('enums2121:JobKind.Requirement');
  if (value === Enum.JobKind.Service)
    enumLabel = t('enums2121:JobKind.Service');
  if (value === Enum.JobKind.Show) enumLabel = t('enums2121:JobKind.Show');
  if (value === Enum.JobKind.Tour) enumLabel = t('enums2121:JobKind.Tour');
  return enumLabel;
};
export const getProjectKindLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.ProjectKind.Abonnement)
    enumLabel = t('enums2121:ProjectKind.Abonnement');
  if (value === Enum.ProjectKind.Business)
    enumLabel = t('enums2121:ProjectKind.Business');
  if (value === Enum.ProjectKind.Event)
    enumLabel = t('enums2121:ProjectKind.Event');
  if (value === Enum.ProjectKind.Semester)
    enumLabel = t('enums2121:ProjectKind.Semester');
  if (value === Enum.ProjectKind.Solution)
    enumLabel = t('enums2121:ProjectKind.Solution');
  if (value === Enum.ProjectKind.Travel)
    enumLabel = t('enums2121:ProjectKind.Travel');
  return enumLabel;
};
export const getPurchaseDocKindLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.PurchaseDocKind.Quote)
    enumLabel = t('enums2121:PurchaseDocKind.PurchaseQuote');
  if (value === Enum.PurchaseDocKind.Order)
    enumLabel = t('enums2121:PurchaseDocKind.PurchaseOrder');
  if (value === Enum.PurchaseDocKind.Invoice)
    enumLabel = t('enums2121:PurchaseDocKind.PurchaseInvoice');
  return enumLabel;
};
export const getResourceKindLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.ResourceKind.Person)
    enumLabel = t('enums2121:ResourceKind.Person');
  if (value === Enum.ResourceKind.Location)
    enumLabel = t('enums2121:ResourceKind.Location');
  if (value === Enum.ResourceKind.Device)
    enumLabel = t('enums2121:ResourceKind.Device');
  return enumLabel;
};
export const getSalesDocKindLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.SalesDocKind.Quote)
    enumLabel = t('enums2121:SalesDocKind.SalesQuote');
  if (value === Enum.SalesDocKind.Order)
    enumLabel = t('enums2121:SalesDocKind.SalesOrder');
  if (value === Enum.SalesDocKind.Invoice)
    enumLabel = t('enums2121:SalesDocKind.SalesInvoice');
  return enumLabel;
};
export const getTaskKindLabel = (t, value) => {
  let enumLabel = emptyString;
  if (value === Enum.TaskKind.Lesson)
    enumLabel = t('enums2121:TaskKind.Lesson');
  if (value === Enum.TaskKind.Seat) enumLabel = t('enums2121:TaskKind.Seat');
  if (value === Enum.TaskKind.Session)
    enumLabel = t('enums2121:TaskKind.Session');
  return enumLabel;
};
